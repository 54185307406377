<template>
  <calendesk-tabs v-show="getStatsLoaded" :items="getItems" :route="route" />
</template>

<script>
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import path from "@/router/path";
import CalendeskTabs from "@/components/CTabs/CalendeskTabs.vue";
import roleActions from "@/calendesk/mixins/roleActions";
import sharedTabsActions from "@/calendesk/mixins/sharedTabsActions";

export default {
  name: "OfferWrapper",
  components: { CalendeskTabs },
  mixins: [sharedActions, roleActions, sharedTabsActions],
  data() {
    return {
      route: path.offer.main,
    };
  },
  computed: {
    getItems() {
      const items = [];

      if (this.loggedUserCanEditServices) {
        items.push({
          id: this.availableTabs.offer.services,
          title: this.$trans("services"),
          props: {},
          route: {
            name: this.route,
            params: { tab: this.availableTabs.offer.services },
          },
          content: () =>
            import("@/views/dashboard/pages/Offer/Categories/Categories"),
        });
      }

      if (this.loggedUserCanEditSubscriptions) {
        items.push({
          id: this.availableTabs.offer.subscriptions,
          title: this.$trans("subscriptions"),
          props: {},
          route: {
            name: this.route,
            params: { tab: this.availableTabs.offer.subscriptions },
          },
          content: () =>
            import(
              "@/views/dashboard/pages/Offer/Subscriptions/SubscriptionsList"
            ),
        });
      }

      if (this.loggedUserCanEditStoreProducts) {
        items.push({
          id: this.availableTabs.offer.products,
          title: this.$trans("simple_store_products"),
          props: {},
          route: {
            name: this.route,
            params: { tab: this.availableTabs.offer.products },
          },
          content: () =>
            import(
              "@/views/dashboard/pages/Offer/SimpleProductStore/SimpleStoreProductList"
            ),
        });
      }

      return items;
    },
  },
};
</script>
